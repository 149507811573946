<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-form-ref"
      title="DMAX Oranı"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      size="lg"
      v-if="dataForm"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <validation-observer ref="saveFormRef">
        <b-form ref="form" @submit.stop.prevent="saveForm">
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Kodu">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sKodu"
                >
                  <b-form-input
                    v-model="dataForm.sKodu"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sAdi"
                >
                  <b-form-input
                    v-model="dataForm.sAdi"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="Standart Adı">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sStandartName"
                >
                  <b-form-input
                    v-model="dataForm.sStandartName"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="" label-for="contact-options" label-class="mb-1">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="dataForm.isActive"
                    class="custom-control-primary"
                  >
                    {{ $t("Aktif Durumu") }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" class="mb-md-0 mb-2">
              <b-table
                :items="dMaxDetails"
                responsive
                :fields="tableColumns"
                class="mb-0"
                show-empty
                sticky-header
                empty-text="Hiçbir eşleşen kayıt bulunamadı"
              >
                <!-- sElekCesitleri -->
                <template #cell(sElekCesitleri)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.sElekCesitleri"
                      type="text"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- ldMax -->
                <template #cell(ldMax)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldMax"
                      type="number"
                      step="any"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- ldMin -->
                <template #cell(ldMin)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldMin"
                      type="number"
                      step="any"
                      size="sm"
                    />
                  </div>
                </template>

                <!-- ldOptimum -->
                <template #cell(ldOptimum)="data">
                  <div class="text-nowrap">
                    <b-form-input
                      v-model="data.item.ldOptimum"
                      type="number"
                      step="any"
                      size="sm"
                    />
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      dataForm: {
        sKodu: null,
        sAdi: null,
        sStandartName: null,
        isActive: true,
      },
      dMaxDetails: [
        { sElekCesitleri: '31,5', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '22,4', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '19', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '16', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '11,2', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '8', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '5,6', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '4', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '2', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '1', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '0,5', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '0,25', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '0,125', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '0,063', ldMax: 0, ldMin: 0, ldOptimum: 0 },
        { sElekCesitleri: '0', ldMax: 0, ldMin: 0, ldOptimum: 0 },
      ],

      tableColumns: [
        { label: 'Elek Çeşitleri', key: 'sElekCesitleri' },
        { label: 'Maximum', key: 'ldMax' },
        { label: 'Minimum', key: 'ldMin' },
        { label: 'Optimum', key: 'ldOptimum' },
      ],
    }
  },
  methods: {
    saveForm() {
      this.$refs.saveFormRef.validate().then(success => {
        if (success) {
          const query = {
            KL_DMAXOrani: this.dataForm,
            KL_DMAXOraniDetays: this.dMaxDetails,
          };

          ApiService.post('manage/kalite/dmaxorani/save', query, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      });
    },

    initialModal() {
      if (this.modalObject.item) {
        this.dataForm = this.modalObject.item;

        ApiService.get(`manage/kalite/dmaxoranidetay/get/${this.dataForm.id}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            if (response.result.length) {
              this.dMaxDetails = response.result;
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
      }
      this.$refs['modal-save-form-ref'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveForm()
    },
    hiddenModal() {
      this.$refs['modal-save-form-ref'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
